<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="机构门店">
      <table-page :key="activeKey" :config="config" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="服务项目">
      <table-page :key="activeKey" :config="config2" />
    </a-tab-pane>
    <a-tab-pane key="3" tab="录取案例">
      <table-page :key="activeKey" :config="config3" />
    </a-tab-pane>
    <a-tab-pane key="4" tab="权重设置">
      <a-card>
        <a-select v-model:value="currType" placeholder="请选择" @change="selectChange" style="width: 150px;">
          <a-select-option v-for="option in types" :key="option.id" :name="option.name" :disabled="option.disabled">{{
    option.name }}
          </a-select-option>
        </a-select>
        <div v-if="currType == '门店信息'">
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px;">预约福利</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.benefitHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.benefitPer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.benefitHigh"
              placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px;">服务项目</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.serviceProjectHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.serviceProjectPer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.serviceProjectHigh"
              placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px;">服务团队</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.serviceTeamHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.serviceTeamPer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.serviceTeamHigh"
              placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px;">荣誉资质</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.honorQualificationHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.honorQualificationPer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.honorQualificationHigh" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px;">
            <span style="margin-right: 8px;">录取案例</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.caseHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.casePer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.caseHigh"
              placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 40px;">
            <span style="margin-right: 8px;">攻略资讯</span>
            <span>每项</span>
            <a-input-number :min="0" :max="form.strategyConsultationHigh" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.strategyConsultationPer" placeholder=""></a-input-number>
            <span>分，最高</span>
            <a-input-number :min="5" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.strategyConsultationHigh" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <p style="color: #999; padding: 20px 0;">每项评分必填，最高分选填(最小填写5分)，最高分为空时代表不设最高分限制。</p>
          <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
        </div>
        <div v-if="currType == '服务项目'">
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">申请国家</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.countryOfApplication" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">服务亮点</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.serviceHighlights" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">申请老师</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.applyTeacher" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">服务详情</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.detail" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <a-button type="primary" @click="submitService" :loading="submitLoading">保存</a-button>
        </div>
        <div v-if="currType == '录取案例'">
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">专业</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.major" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">申请专业</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.applyingForAMajor" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
            <span style="margin-right: 8px; width: 70px;">申请老师</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.applyTeacher" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">offer图片</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.offer" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">案例名</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.caseName" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">服务亮点</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.serviceHighlights" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">服务类型</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.serviceType" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px;">
            <span style="margin-right: 8px; width: 70px;">案例介绍</span>
            <span>每项</span>
            <a-input-number :min="0" style="width: 50px; margin: 0 6px;"
              v-model:value.trim="form.caseIntroduction" placeholder=""></a-input-number>
            <span>分</span>
          </div>
          <a-button type="primary" @click="submitCase" :loading="submitLoading">保存</a-button>
        </div>
      </a-card>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { get } from "@/utils/http";
export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config: {
        dataUrl: `/sortrule/operation/list`,
        filter: {
          controls: [
            {
              key: 'orgName',
              label: '机构名',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'orgName',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'caseNumber',
            title: '门店信息',
            width: '130px',
            type: 'modal',
            config: {
              text: (row) => {
                return (parseFloat(row.benefitNumber) + parseFloat(row.serviceProject) + parseFloat(row.serviceTeam) + parseFloat(row.honorQualification) + parseFloat(row.caseNumber) + parseFloat(row.strategyConsultation)).toFixed(2)
              },
              title: '门店信息明细',
              content: (row) => {
                let html = `<div style="margin-bottom: 8px;text-align: center;">预约福利：${row.benefitNumber}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务项目：${row.serviceProject}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务团队：${row.serviceTeam}</div>
                            <div style="margin-bottom: 8px;text-align: center;">荣誉资质：${row.honorQualification}</div>
                            <div style="margin-bottom: 8px;text-align: center;">录取案例：${row.caseNumber}</div>
                            <div style="margin-bottom: 8px;text-align: center;">攻略资讯：${row.strategyConsultation}</div>
                            <div style="text-align: center;">合计：${(parseFloat(row.benefitNumber) + parseFloat(row.serviceProject) + parseFloat(row.serviceTeam) + parseFloat(row.honorQualification) + parseFloat(row.caseNumber) + parseFloat(row.strategyConsultation)).toFixed(2)}</div>`
                return html
              }
            }
          },
          {
            key: 'number',
            title: '运营系数',
            width: '130px',
          },
          {
            key: 'totalScore',
            title: '总分',
            width: '130px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: 60
          }
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '编辑运营系数',
            type: 'blank',
            permission: '/sortrule/operation/edit',
            config: {
              title: '编辑运营系数',
              component: () => import("./editOperate")
            }
          }
        ]
      },
      config2: {
        dataUrl: `/sortrule/operation/serviceProject/list`,
        filter: {
          controls: [
            {
              key: 'orgName',
              label: '机构名',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'serviceName',
            title: '服务项目',
            width: '130px',
          },
          {
            key: 'orgName',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'caseNumber',
            title: '项目信息',
            width: '130px',
            type: 'modal',
            config: {
              text: (row) => {
                return (parseFloat(row.countryOfApplication) + parseFloat(row.serviceHighlights) + parseFloat(row.applyTeacher) + parseFloat(row.detail)).toFixed(2)
              },
              title: '项目信息明细',
              content: (row) => {
                let html = `<div style="margin-bottom: 8px;text-align: center;">申请国家：${row.countryOfApplication}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务亮点：${row.serviceHighlights}</div>
                            <div style="margin-bottom: 8px;text-align: center;">申请老师：${row.applyTeacher}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务详情：${row.detail}</div>
                            <div style="text-align: center;">合计：${(parseFloat(row.countryOfApplication) + parseFloat(row.serviceHighlights) + parseFloat(row.applyTeacher) + parseFloat(row.detail)).toFixed(2)}</div>`
                return html
              }
            }
          },
          {
            key: 'number',
            title: '运营系数',
            width: '130px',
          },
          {
            key: 'totalScore',
            title: '总分',
            width: '130px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: 60
          }
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '编辑运营系数',
            type: 'blank',
            permission: '/sortrule/operation/edit',
            config: {
              title: '编辑运营系数',
              component: () => import("./editOperateService")
            }
          }
        ]
      },
      config3: {
        dataUrl: `/sortrule/operation/case/list`,
        filter: {
          controls: [
            {
              key: 'orgName',
              label: '机构名',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'applyingToASchool',
            title: '录取院校',
            width: '130px',
          },
          {
            key: 'applyingForADegree',
            title: '申请学位',
            width: '130px',
          },
          {
            key: 'orgName',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'caseNumber',
            title: '案例信息',
            width: '130px',
            type: 'modal',
            config: {
              text: (row) => {
                return (parseFloat(row.major) + parseFloat(row.applyingForAMajor) + parseFloat(row.applyTeacher) + parseFloat(row.offer) + parseFloat(row.caseName) + parseFloat(row.serviceHighlights) + parseFloat(row.serviceType) + parseFloat(row.caseIntroduction)).toFixed(2)
              },
              title: '案例信息明细',
              content: (row) => {
                let html = `<div style="margin-bottom: 8px;text-align: center;">专业：${row.major}</div>
                            <div style="margin-bottom: 8px;text-align: center;">申请专业：${row.applyingForAMajor}</div>
                            <div style="margin-bottom: 8px;text-align: center;">申请老师：${row.applyTeacher}</div>
                            <div style="margin-bottom: 8px;text-align: center;">offer图片：${row.offer}</div>
                            <div style="margin-bottom: 8px;text-align: center;">案例名：${row.caseName}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务亮点：${row.serviceHighlights}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务类型：${row.serviceType}</div>
                            <div style="margin-bottom: 8px;text-align: center;">案例介绍：${row.caseIntroduction}</div>
                            <div style="text-align: center;">合计：${(parseFloat(row.major) + parseFloat(row.applyingForAMajor) + parseFloat(row.applyTeacher) + parseFloat(row.offer) + parseFloat(row.caseName) + parseFloat(row.serviceHighlights) + parseFloat(row.serviceType) + parseFloat(row.caseIntroduction)).toFixed(2)}</div>`
                return html
              }
            }
          },
          {
            key: 'number',
            title: '运营系数',
            width: '130px',
          },
          {
            key: 'totalScore',
            title: '总分',
            width: '130px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: 60
          }
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '编辑运营系数',
            type: 'blank',
            permission: '/sortrule/operation/edit',
            config: {
              title: '编辑运营系数',
              component: () => import("./editOperateCase")
            }
          }
        ]
      },
      form: {},
      submitLoading: false,
      types: [
        {
          id: '门店信息',
          name: '门店信息',
        },
        {
          id: '服务项目',
          name: '服务项目',
        },
        {
          id: '录取案例',
          name: '录取案例',
        }
      ],
      currType: '门店信息'
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    selectChange(e) {
      this.init()
    },

    init() {
      if(this.currType == '门店信息') {
        this.getDetail();
      } else if(this.currType == '服务项目') {
        this.getServiceProject();
      } else if(this.currType == '录取案例') {
        this.getCase()
      } 
    },


    //门店get
    async getDetail() {
      try {
        let res = await get("/sortrule/weight/get")
        if (res.code == '200') {
          this.form = res.data;
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async submit() {
      if (!this.form.serviceProjectPer
        || !this.form.serviceTeamPer
        || !this.form.honorQualificationPer
        || !this.form.casePer
        || !this.form.strategyConsultationPer
        || !this.form.benefitPer
      ) {
        this.$message.error('请输入每项分数');
        return
      }
      try {
        await this.$http.post('/sortrule/weight/edit', {
          id: this.form.id,
          serviceProjectPer: this.form.serviceProjectPer,
          serviceProjectHigh: this.form.serviceProjectHigh,
          serviceTeamPer: this.form.serviceTeamPer,
          serviceTeamHigh: this.form.serviceTeamHigh,
          honorQualificationPer: this.form.honorQualificationPer,
          honorQualificationHigh: this.form.honorQualificationHigh,
          casePer: this.form.casePer,
          caseHigh: this.form.caseHigh,
          strategyConsultationPer: this.form.strategyConsultationPer,
          strategyConsultationHigh: this.form.strategyConsultationHigh,
          benefitPer: this.form.benefitPer,
          benefitHigh: this.form.benefitHigh,
        });
        this.$message.success('设置成功');
        this.getDetail();
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    //服务get
    async getServiceProject() {
      try {
        let res = await get("/sortrule/weight/serviceProject/get")
        if (res.code == '200') {
          this.form = res.data;
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async submitService() {
      if (!this.form.countryOfApplication
        || !this.form.serviceHighlights
        || !this.form.applyTeacher
        || !this.form.detail
      ) {
        this.$message.error('请输入每项分数');
        return
      }
      try {
        await this.$http.post('/sortrule/weight/serviceProject/edit', {
          id: this.form.id,
          countryOfApplication: this.form.countryOfApplication,
          serviceHighlights: this.form.serviceHighlights,
          applyTeacher: this.form.applyTeacher,
          detail: this.form.detail,
        });
        this.$message.success('设置成功');
        this.getServiceProject();
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    //案例get
    async getCase() {
      try {
        let res = await get("/sortrule/weight/case/get")
        if (res.code == '200') {
          this.form = res.data;
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async submitCase() {
      if (!this.form.major
        || !this.form.applyingForAMajor
        || !this.form.applyTeacher
        || !this.form.offer
        || !this.form.caseName
        || !this.form.serviceHighlights
        || !this.form.serviceType
      ) {
        this.$message.error('请输入每项分数');
        return
      }
      try {
        await this.$http.post('/sortrule/weight/case/edit', {
          id: this.form.id,
          major: this.form.major,
          applyingForAMajor: this.form.applyingForAMajor,
          applyTeacher: this.form.applyTeacher,
          offer: this.form.offer,
          caseName: this.form.caseName,
          serviceHighlights: this.form.serviceHighlights,
          serviceType: this.form.serviceType,
          caseIntroduction: this.form.caseIntroduction,
        });
        this.$message.success('设置成功');
        this.getCase();
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },
  }

};
</script>

<style lang="less" scoped></style>